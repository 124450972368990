/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/login.css';
require('../css/fontawesome/all.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
const $ = require('jquery');
global.$ = global.jQuery = window.jQuery = window.$  = $;
require('bootstrap');
require('jquery.easing');

(function($) {
  "use strict"; // Start of use strict

  // Toggle the side navigation
  $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $('.sidebar .collapse').collapse('hide');
    };
  });

  // Close any open menu accordions when window is resized below 768px
  $(window).resize(function() {
    if ($(window).width() < 768) {
      $('.sidebar .collapse').collapse('hide');
    };
  });

  // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
  $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function(e) {
    if ($(window).width() > 768) {
      var e0 = e.originalEvent,
        delta = e0.wheelDelta || -e0.detail;
      this.scrollTop += (delta < 0 ? 1 : -1) * 30;
      e.preventDefault();
    }
  });

  // Scroll to top button appear
  $(document).on('scroll', function() {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
      $('.scroll-to-top').fadeIn();
    } else {
      $('.scroll-to-top').fadeOut();
    }
  });

  // Smooth scrolling using jQuery easing
  $(document).on('click', 'a.scroll-to-top', function(e) {
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: ($($anchor.attr('href')).offset().top)
    }, 1000, 'easeInOutExpo');
    e.preventDefault();
  });

  $('.btn-valid-mdp').on('click',function(e) {
      $('#error').css('display','none');      
      if($("#password").val().length<8){
        $('#error').css('display','inline-block');
        $('#error').html('Le mot de passe doit contenir aumoins 8 caractères.');
        return;
      }
      if(!$("#password").val().match(/[a-z]/)){
        $('#error').css('display','inline-block');
        $('#error').html('Le mot de passe doit contenir des lettres minuscules.');
        return;
      }
      if(!$("#password").val().match(/[A-Z]/)){
        $('#error').css('display','inline-block');
        $('#error').html('Le mot de passe doit contenir aumoins une lettre majuscule.');
        return;
      }
      if(!$("#password").val().match(/\d/)){
        $('#error').css('display','inline-block');
        $('#error').html('Le mot de passe doit contenir aumoins un chiffre.');
        return;
      }
      if($("#password").val()!=$("#password_r").val()){
        $('#error').css('display','inline-block');
        $('#error').html('Le mot de passe et sa réécriture ne sont pas identique.');
        return;
      }
      $('.btn-forgot').click();
  });

})(jQuery); // End of use strict

